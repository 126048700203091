<template>
  <Page>
    <Card content-class="c-table">
      <SlideForm />
    </Card>
  </Page>
</template>

<script>
import SlideForm from "@/components/forms/Slide.vue";
export default {
  components: { SlideForm },
  created() {
    this.updateTitle(this.$tc("slide", 2));
  }
};
</script>
